.application-form {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{var(--header-height-mobile)});
  justify-content: space-between;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    min-height: calc(100vh - #{var(--header-height-desktop)});
    justify-content: flex-start;
  }
}

.application-form-central-container {
  justify-content: center;
  @media (min-width: $tablet-width) {
    //background: var(--stage-indicator-background-color);
  }
  .application-form-container {
    .individual-application-created {
      @media (min-width: $tablet-width) {
        background: var(--body-background-color);
        padding: var(--base-horizontal-padding) var(--base-vertical-padding)
      }
    }
  }
}

.application-form-container {
  padding: var(--base-vertical-padding) var(--base-horizontal-padding);
  max-width: var(--form-max-width);

  .form-type-buttons {
    display: flex;
    margin: 1rem 0;
    flex-direction: column;
  }

  .form-type {
    display: flex;
    align-items: center;
    border: 1px solid var(--light-border);
    cursor: pointer;
    max-width: 100%;
    height: 100px;
    background: var(--form-type-background-color);
    color: var(--form-type-color);
    font-size: var(--form-type-font-size);
    margin-bottom: 0.6rem;
    white-space: break-spaces;
    border-radius: var(--border-radius-default);
    padding: 0.5rem;
    line-height: 1rem;

    .icon {
      margin-right: 10px;
    }

    &.selected {
      border-color: var(--selected-border);
      color: var(--text-selected-color);
    }

    .type-title {
      font-weight: bold;
    }

    @media (min-width: $tablet-width) {
      font-size: 0.5em;
      height: auto;
      line-height: 0.6rem;
      min-height: 50px;
    }
  }

  @media (min-width: $tablet-width) {
    order: 2;
    flex-grow: 1;
  }

  .back-button {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    vertical-align: top;

    &.disabled {
      visibility: hidden;
    }

    .icon {
      margin-right: 4px;
    }
  }

  .phone-verification {
    max-width: 700px;
  }

  input[disabled] {
    color: var(--input-disabled-color);
  }
}

.form-current-stage-indicator {
  padding: var(--base-vertical-padding) var(--base-horizontal-padding) 3rem;
  background: var(--stage-indicator-background-color);
  box-shadow: var(--stage-indicator-box-shadow);
  font-size: var(--stage-indicator-font-size);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre;
  position: sticky;
  bottom: 0;

  .stages {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      height: 2px;
      background: #EEEFF1;

      @media (min-width: $tablet-width) {
        top: 0.4rem;
        bottom: 0.4rem;
        left: 0.32rem;
        transform: initial;
        height: initial;
        width: 2px;
      }
    }

    .stage {
      display: flex;
      align-items: center;
      font-size: 0.5625rem;

      @media (min-width: $tablet-width) {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .stage-circle {
        width: 0.6875rem;
        height: 0.6875rem;
        border-radius: 50%;
        background: #EEEFF1;
        border: 1px solid #D2D4DB;
        z-index: 1;
        position: relative;

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .stage-text {
        display: none;

        @media (min-width: $tablet-width) {
          display: block;
          margin-left: 0.4rem;
          opacity: 0.5;
        }
      }

      &.hidden {
        display: none;
      }

      &.clickable {
        cursor: pointer;
      }

      &.completed {

        .stage-circle {
          background: var(--success-color);

          &:after {
            color: #FFFFFF;
            content: '✔';
            font-size: 0.6em;
          }
        }
      }

      &.current {
        .stage-circle {
          background: #FFFFFF;
          border-color: #FFFFFF;
          box-shadow: 0px 3px 6px rgba(198, 198, 198, 0.5);

          &:after {
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            content: ' ';
            background: var(--success-color);
          }
        }

        .stage-text {
          @media (min-width: $tablet-width) {
            opacity: 1;
          }
        }
      }
    }

    @media (min-width: $tablet-width) {
      flex-direction: column;;
    }
  }

  @media (min-width: $tablet-width) {
    position: initial;
    padding: var(--base-vertical-padding) var(--base-horizontal-padding);
    width: 390px;
    flex-grow: 0;
    flex-shrink: 0;
    order: 1;
    box-shadow: 0px 5px 5px -5px rgba(15, 23, 61, 0.25);
    justify-content: flex-start;
  }

  .bottom-text {
    @media (min-width: $tablet-width) {
      display: none;
    }
  }
}

.beneficial-owner {
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  margin-top: 1rem;

  .beneficial-owner-title {
    padding: 0.8rem 1rem;
    border-bottom: 1px solid var(--textfield-border-color);
  }

  .beneficial-owner-form {
    padding: 0.8rem 1rem;
  }
}

.business-contact {
  margin-top: 1.2rem;
}

.form-type-selection {
  .submit-wrapper {
    margin-top: 1rem;

    button {

    }
  }
}

.agreement-checkbox {
  .links {
    font-size: 0.5em;
    line-height: 0.8rem;
    margin-left: 0.9rem;
  }
}

.bank-notice {
  font-size: 0.5em;
}

.address {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);

  h4 {
    margin-bottom: 1rem;

    .tooltip-icon {
      font-size: 0.5rem;
    }
  }
}

.address {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);

  h4 {
    margin-bottom: 1rem;

    .tooltip-icon {
      font-size: 0.5rem;
    }
  }
}

.application-status {
  h1, p {
    margin-bottom: 1.5rem;
  }

  .approved {
    .icon {
      color: var(--success-color);
    }
  }

  .denied {
    .icon {
      color: var(--invalid-color);
    }
  }

  .pending {

    p {
      white-space: pre-line;
    }

    .loading {
      font-size: 0.6em;
      text-align: center;
      line-height: 1rem;
    }

    .buttons {
      display: flex;

      .try-again-button {
        position: static;
        width: 100%;

        @media (min-width: $tablet-width) {
          margin-right: 10px;
        }
      }
    }
  }
}

.application-documents {
  .doc {
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-default);
    overflow: hidden;
    margin-bottom: 1rem;

    .doc-details {
      padding: 0.66rem 1rem;
      font-size: var(--p-font-size);
      background-color: var(--stage-indicator-background-color);
      border-bottom: 1px solid var(--light-border);
      line-height: var(--p-line-height);

      .doc-type {
        color: var(--text-selected-color);
        font-weight: bold;
      }

      .description {
        color: var(--text-primary-color);
        margin-top: 0.5rem;
      }
    }

    .doc-status {
      padding: 0.66rem 1rem;
      font-size: 0.5625rem;
      line-height: 1.2rem;

      .upload-title {
        font-size: 0.45rem;
        line-height: 1.1;
        margin-bottom: 0.5rem;
      }

      .loading {
        font-size: 0.75rem;
        line-height: 1.2rem;
        display: flex;
        padding: 0.25rem 0.5rem;
        margin-top: 0.5rem;
      }

      .upload-button {
        border: 1px solid black;
        font-size: 0.5625rem;
        line-height: 1.2rem;
        padding: 0 0.5rem;
        border-radius: var(--border-radius-default);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        input[type=file] {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &, &::-webkit-file-upload-button {
            cursor: pointer;
          }
        }
      }

      .document-approved, .document-received {
        .icon {
          color: var(--success-color);
        }
      }

      .document-received, .upload-error {
        display: flex;
        align-items: center;
        margin: 0 -1rem;
        padding: 0 1rem;
        min-height: 1.6rem;
        background-color: var(--success-background-color);
        line-height: 1.2;
      }

      .upload-error {
        background-color: var(--error-background-color);
        justify-content: space-between;

        .icon {
          color: var(--error-color);
        }

        .upload-button {
          padding: 0;
          border: none;
        }
      }

      .document-invalid {
        .icon {
          color: var(--error-color);
        }

        .reason {
          font-size: 0.45rem;
          line-height: 1.2;
        }
      }
    }

    .upload-row {
      font-size: 0.5625rem;

      &:not(:first-child) {
        margin-top: 0.5rem;
      }

      .or {
        margin: 0 0.5rem;
      }
    }
  }
}

.verification-container {
  justify-content: space-between;
  max-width: 480px;

  >div{
    .verification-input {
      height: 1em;
      font-size: 15px;
      box-sizing: content-box;
      border-radius: 4px;

      margin-left: 0.25em;
      margin-right: 0.25em;

      @media (min-width: $tablet-width) {
        font-size: 24px;
      }
    }

    &:first-child{
      .verification-input {
        margin-left: 0;
      }
    }

    &:last-child{
      .verification-input {
        margin-right: 0;
      }
    }
  }

}
