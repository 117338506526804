html, body {
  font-family: var(--font-family);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  background-color: var(--body-background-color);

  @media (min-width: $tablet-width) {
    font-size: calc(var(--base-font-size) * 1.333);
  }
}

.layout-header {
  height: var(--header-height-mobile);
  background-color: var(--header-background-color);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--light-border);
  padding-left: var(--base-horizontal-padding);

  img {
    height: 24px;
  }

  @media (min-width: $tablet-width) {
    height: var(--header-height-desktop);

    img {
      height: 48px;
    }
  }
}

.layout-content {

}

h1 {
  font-size: var(--h1-font-size);
  color: var(--h1-color);
  line-height: var(--h1-line-height);
  font-weight: bold;
  margin-top: 0.5em;
}

h3 {
  font-size: var(--h3-font-size);
  color: var(--h3-color);
  line-height: var(--h3-line-height);
}

h4 {
  font-size: var(--h4-font-size);
  color: var(--h4-color);
  line-height: var(--h4-line-height);
}

h5 {
  font-size: var(--h5-font-size);
  color: var(--h5-color);
  line-height: var(--h5-line-height);
}

h6 {
  font-size: 0.5rem;
  line-height: 0.6rem;
  color: var(--success-color);
}

p {
  font-size: var(--p-font-size);
  line-height: var(--p-line-height);
  color: var(--text-primary-color);
  margin-top: 0.5em;

  &.agreement {
    background: #F7F9FC;
    border: 1px solid #E2E4E5;
    padding: 0.75rem;
    margin-top: 1rem;
    white-space: pre-wrap;
  }
}

h1, h6, p {
  &:first-child {
    margin-top: 0;
  }
}

.textfield, .dropdown, .single-selection {
  label {
    display: flex;
    flex-direction: column;

    .label-text {
      font-size: 0.5em;
      color: var(--input-label-color);
      font-weight: 600;
      line-height: 1rem;
      margin-top: 0.5rem;
    }
  }
}

.single-selection {
  .single-selection-options {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.625em;
      border: 1px solid var(--input-border-color);
      border-radius: var(--border-radius-default);
      width: calc(50% - 0.4rem);
      height: 100px;
      cursor: pointer;
      padding: 10px;
      line-height: 1.2;
      text-align: center;
      color: var(--text-primary-medium-color);
      flex-shrink: 0;

      @media (min-width: $tablet-width) {
        width: 169px;
      }

      &.selected {
        border-color: var(--selected-border);
        color: var(--text-selected-color);
      }
    }
  }

  &.disabled {
    .single-selection-options {
      .option {
        background-color: var(--input-disabled-background-color);
        cursor: default;
      }
    }
  }
}

.checkbox {
  margin-top: 1rem;

  label {
    display: flex;
    font-size: 0.5em;

    input[type=checkbox] {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .text {
      line-height: 1.5;
      margin-left: 0.2rem;
      margin-top: 0.05rem;
      color: var(--input-label-color);
      font-weight: 700;
    }
  }

  .form-error {
    padding-left: 0.9rem;
  }
}

.textfield, .dropdown, .checkbox {
  &.error {
    input, select {
      &:not(:focus) {
        border-color: var(--error-color);
      }
    }
  }
}

.form-error {
  color: var(--error-color);
  font-size: 0.4em;
  line-height: 2;
  margin-bottom: 1rem;
}

input, select, .multi-selection-combobox {
  font-size: 0.5em;
  padding: 0.5em;
  height: 1.75rem;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  font-family: var(--font-family);
  outline: none;

  &:focus {
    border-color: var(--input-focus-border-color);
  }
}

select {
  appearance: none;
  background: url('data:image/svg+xml,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87767 3.36615L2.13359 0.62207L0.955078 1.80058L4.28841 5.13392C4.44469 5.2902 4.65665 5.37799 4.87767 5.37799C5.09868 5.37799 5.31064 5.2902 5.46692 5.13392L8.80025 1.80058L7.62174 0.62207L4.87767 3.36615Z" fill="currentcolor"/> </svg>') no-repeat;
  background-position: calc(100% - 0.375rem);
}

.multi-selection-combobox {
  min-height: 1.75rem;
  height: initial;

  .select__control, .select__placeholder, .select__value-input-container, .select__value-container {
    min-height: 1.25rem;
    border: none;
    padding: 0;
    margin: 0;
    line-height: 1.25rem;
    box-shadow: none;
  }

  .select__control--is-disabled {
    background-color: var(--input-disabled-background-color);
  }

  .select__value-container {
    align-items: initial;
  }

  .select__multi-value {
    background: var(--light-background);
    border-radius: 999px;
    height: 1.25rem;
    margin: 0 0 0 6px;

    &:first-child {
      margin-left: 0;
    }
  }

  .select__multi-value__label {
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
  }

  .select__multi-value__remove {
    padding: 0 12px 0 6px;
    color: var(--neutral-dark);
    cursor: pointer;
    background: none;

    &:hover {
      background: none;
      color: var(--selected-border);
    }
  }

  .select__input-container {
    margin: 0 6px 0 0;
    padding: 0;
    height: 1.25rem;

    input {
      height: 100%;
      outline: none;
      font-size: 0.4375rem;

      &:after {
        content: ""
      }
    }
  }

  .select__dropdown-indicator {
    margin: 8px 4px 8px 12px;
    display: flex;
  }

  .select__menu {
    z-index: 3;
    margin-top: 0;
  }

  .select__indicators {
    align-self: flex-start;
  }

  .select__option {
    padding: 0 0.75rem 0 0.75rem;
    font-size: 0.4375rem;
    height: 1rem;
    line-height: 1rem;
  }

  .select__option--is-focused {
    background: var(--light-background);
  }

  .select-remove-icon {
    background: red;
    width: 10px;
  }
}

input[type=checkbox] {
  width: 0.7rem;
  height: 0.7rem;
  padding: 0;
  margin: 0;
}

form {
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.two {
      > * {
        flex-grow: 1;
        max-width: calc(50% - 0.4em);
      }
    }
  }
}

a[href] {
  color: var(--link-color);
}

a.form-button, button[type=submit] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--button-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  color: var(--button-color);
  background: var(--button-background-color);
  border: none;
  border-radius: var(--border-radius-default/2);
  cursor: pointer;
  text-decoration: none;
  margin-top: 1rem;

  position: fixed;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  width: calc(100% - 2rem);
  z-index: 1;

  @media (min-width: $tablet-width) {
    position: initial;
    margin-top: 1rem;
    max-width: 320px;
    width: 100%;
  }
}

a.form-button:hover, button[type=submit]:hover:enabled {
  background: var(--button-hover-background-color);
}

button:disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.tooltip {
  line-height: 1.2;
  max-width: 400px;
}

div.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker {
  zoom: 0.7;
  line-height: 1.1;

  input, select {
    font-size: 0.75rem;
    height: initial;
    margin: 0.3rem 0;
  }

  @media (min-width: $tablet-width) {
    zoom: 0.5;
  }

  .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    zoom: 1.5;
  }
}

.react-datepicker-wrapper {
  &, input {
    width: 100%;
  }
}

.admonition {
  display: flex;

  &.is-warning {
    .admonition-message-body {
      border-color: #FF9D42;
      background-color: rgba(255, 157, 66, 0.04);
      color: #000000;
    }
  }
  &.is-danger {
    .admonition-message-body {
      border-color: #FF506F;
      background-color: #FFF8F9;
      color: #000000;
    }
  }
  &.is-info {
    .admonition-message-body {
      border-color: #31D5FA;
      background-color: rgba(49, 213, 250, 0.09);
      color: #000000;
    }
  }
  &.is-security-info {
    .admonition-message-body {
      border-color: #2E75FF;
      background-color: rgba(46, 117, 255, 0.05);
      color: #000000;
    }
  }
  .admonition-message-body {
    font-size: 14px;
    line-height: 21px;
    padding: 15px 20px;
    border: 0 solid #dbdbdb;
    border-left-width: 4px;
    border-radius: 4px;
  }
  .admonition-message-body-title {
    display: flex;

    .admonition-message-body-title-text {
      font-weight: 600;
      font-size: 14px;
      padding-left: 13px;
    }
  }
  .admonition-message-body-container {
    padding-left: 30px;
  }
}

.data-collection-person-section:not(:first-child) {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: 1px solid #D9D9D9;
}

.data-collection-subheader {
  font-size: 0.5rem;
  color: #7a7a7a;
  line-height: 1rem;
  display: flex;
  align-items: center;
  gap: 4px;

  .icon {
    font-size: 0.375rem;
  }
}