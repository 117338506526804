$tablet-width: 769px;

:root {
  --font-family: Helvetica;
  --base-font-size: 24px;
  --base-line-height: 1.5em;
  --base-horizontal-padding: 2em;
  --base-vertical-padding: 1.5em;

  --header-background-color: #FFFFFF;
  --header-height-mobile: 60px;
  --header-height-desktop: 120px;

  --body-background-color: #FFFFFF;
  --body-color: #000000;
  --text-primary-color: #575F6E;
  --text-primary-medium-color: #B0B2B5;
  --text-selected-color: #000000;
  --light-border: rgba(0, 0, 0, 0.2);
  --light-background: #F8F8F8;
  --selected-border: #000000;
  --success-color: #65E984;
  --success-background-color: rgba(15, 206, 23, 0.05);
  --error-color: #FF4240;
  --link-color: var(--text-primary-color);
  --error-background-color: rgba(255, 66, 64, 0.05);
  --invalid-color: #00000020;
  --neutral-dark: #808285;

  --stage-indicator-background-color: #F7F9FC;
  --stage-indicator-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  --stage-indicator-font-size: 0.625em;

  --form-type-background-color: #FFFFFF;
  --form-type-color: #33333370;
  --form-type-font-size: 0.666em;

  --button-background-color: #000000;
  --button-hover-background-color: var(--success-color);
  --button-color: #FFFFFF;
  --button-height: 1.4rem;
  --button-font-size: 0.5625rem;

  --h1-color: #000000;
  --h1-font-size: 1.0625rem;
  --h1-line-height: 1.2rem;

  --h3-color: #000000;
  --h3-font-size: 0.625rem;
  --h3-line-height: 1;

  --h4-color: #BDBCBC;
  --h4-font-size: 0.625rem;
  --h4-line-height: 0.75rem;

  --h5-color: #575F6E;
  --h5-font-size: 0.6rem;
  --h5-line-height: 0.7rem;

  --input-label-color: #242426;
  --input-border-color: #E2E4E5;
  --input-background-color: #FFFFFF;
  --input-focus-border-color: #000000;
  --input-disabled-color: #888888;
  --input-disabled-background-color: rgba(239, 239, 239, 0.3);

  --p-color: var(--text-primary-color);
  --p-font-size: 0.5625rem;
  --p-line-height: 0.8rem;

  // Misc;
  --border-radius-default: 8px;
  --form-max-width: 850px;
}