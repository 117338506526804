@font-face {
  font-family: Neue Haas Grotesk Disp W01_55R;
  src: url("6e329389-9c44-48b0-8291-f918840fe862.8a3393ca.woff2") format("woff2"), url("dc6a6646-e0ac-4deb-b3c0-19e5dc30bf6a.a49b91c6.woff") format("woff");
}

:root {
  --font-family: Helvetica;
  --base-font-size: 24px;
  --base-line-height: 1.5em;
  --base-horizontal-padding: 2em;
  --base-vertical-padding: 1.5em;
  --header-background-color: #fff;
  --header-height-mobile: 60px;
  --header-height-desktop: 120px;
  --body-background-color: #fff;
  --body-color: #000;
  --text-primary-color: #575f6e;
  --text-primary-medium-color: #b0b2b5;
  --text-selected-color: #000;
  --light-border: rgba(0, 0, 0, .2);
  --light-background: #f8f8f8;
  --selected-border: #000;
  --success-color: #65e984;
  --success-background-color: rgba(15, 206, 23, .05);
  --error-color: #ff4240;
  --link-color: var(--text-primary-color);
  --error-background-color: rgba(255, 66, 64, .05);
  --invalid-color: rgba(0, 0, 0, .125);
  --neutral-dark: #808285;
  --stage-indicator-background-color: #f7f9fc;
  --stage-indicator-box-shadow: 0px 0px 10px rgba(0, 0, 0, .15);
  --stage-indicator-font-size: .625em;
  --form-type-background-color: #fff;
  --form-type-color: rgba(51, 51, 51, .44);
  --form-type-font-size: .666em;
  --button-background-color: #000;
  --button-hover-background-color: var(--success-color);
  --button-color: #fff;
  --button-height: 1.4rem;
  --button-font-size: .5625rem;
  --h1-color: #000;
  --h1-font-size: 1.0625rem;
  --h1-line-height: 1.2rem;
  --h3-color: #000;
  --h3-font-size: .625rem;
  --h3-line-height: 1;
  --h4-color: #bdbcbc;
  --h4-font-size: .625rem;
  --h4-line-height: .75rem;
  --h5-color: #575f6e;
  --h5-font-size: .6rem;
  --h5-line-height: .7rem;
  --input-label-color: #242426;
  --input-border-color: #e2e4e5;
  --input-background-color: #fff;
  --input-focus-border-color: #000;
  --input-disabled-color: #888;
  --input-disabled-background-color: rgba(239, 239, 239, .3);
  --p-color: var(--text-primary-color);
  --p-font-size: .5625rem;
  --p-line-height: .8rem;
  --border-radius-default: 8px;
  --form-max-width: 850px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  font-family: var(--font-family);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  background-color: var(--body-background-color);
}

@media (min-width: 769px) {
  html, body {
    font-size: calc(var(--base-font-size) * 1.333);
  }
}

.layout-header {
  height: var(--header-height-mobile);
  background-color: var(--header-background-color);
  border-bottom: 1px solid var(--light-border);
  padding-left: var(--base-horizontal-padding);
  align-items: center;
  display: flex;
}

.layout-header img {
  height: 24px;
}

@media (min-width: 769px) {
  .layout-header {
    height: var(--header-height-desktop);
  }

  .layout-header img {
    height: 48px;
  }
}

h1 {
  font-size: var(--h1-font-size);
  color: var(--h1-color);
  line-height: var(--h1-line-height);
  margin-top: .5em;
  font-weight: bold;
}

h3 {
  font-size: var(--h3-font-size);
  color: var(--h3-color);
  line-height: var(--h3-line-height);
}

h4 {
  font-size: var(--h4-font-size);
  color: var(--h4-color);
  line-height: var(--h4-line-height);
}

h5 {
  font-size: var(--h5-font-size);
  color: var(--h5-color);
  line-height: var(--h5-line-height);
}

h6 {
  color: var(--success-color);
  font-size: .5rem;
  line-height: .6rem;
}

p {
  font-size: var(--p-font-size);
  line-height: var(--p-line-height);
  color: var(--text-primary-color);
  margin-top: .5em;
}

p.agreement {
  white-space: pre-wrap;
  background: #f7f9fc;
  border: 1px solid #e2e4e5;
  margin-top: 1rem;
  padding: .75rem;
}

h1:first-child, h6:first-child, p:first-child {
  margin-top: 0;
}

.textfield label, .dropdown label, .single-selection label {
  flex-direction: column;
  display: flex;
}

.textfield label .label-text, .dropdown label .label-text, .single-selection label .label-text {
  color: var(--input-label-color);
  margin-top: .5rem;
  font-size: .5em;
  font-weight: 600;
  line-height: 1rem;
}

.single-selection .single-selection-options {
  flex-wrap: wrap;
  gap: 12px;
  display: flex;
}

.single-selection .single-selection-options .option {
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  width: calc(50% - .4rem);
  height: 100px;
  cursor: pointer;
  text-align: center;
  color: var(--text-primary-medium-color);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: .625em;
  line-height: 1.2;
  display: flex;
}

@media (min-width: 769px) {
  .single-selection .single-selection-options .option {
    width: 169px;
  }
}

.single-selection .single-selection-options .option.selected {
  border-color: var(--selected-border);
  color: var(--text-selected-color);
}

.single-selection.disabled .single-selection-options .option {
  background-color: var(--input-disabled-background-color);
  cursor: default;
}

.checkbox {
  margin-top: 1rem;
}

.checkbox label {
  font-size: .5em;
  display: flex;
}

.checkbox label input[type="checkbox"] {
  flex-grow: 0;
  flex-shrink: 0;
}

.checkbox label .text {
  color: var(--input-label-color);
  margin-top: .05rem;
  margin-left: .2rem;
  font-weight: 700;
  line-height: 1.5;
}

.checkbox .form-error {
  padding-left: .9rem;
}

.textfield.error input:not(:focus), .textfield.error select:not(:focus), .dropdown.error input:not(:focus), .dropdown.error select:not(:focus), .checkbox.error input:not(:focus), .checkbox.error select:not(:focus) {
  border-color: var(--error-color);
}

.form-error {
  color: var(--error-color);
  margin-bottom: 1rem;
  font-size: .4em;
  line-height: 2;
}

input, select, .multi-selection-combobox {
  height: 1.75rem;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  font-size: .5em;
  font-family: var(--font-family);
  outline: none;
  padding: .5em;
}

input:focus, select:focus, .multi-selection-combobox:focus {
  border-color: var(--input-focus-border-color);
}

select {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,<svg width=\"9\" height=\"6\" viewBox=\"0 0 9 6\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.87767 3.36615L2.13359 0.62207L0.955078 1.80058L4.28841 5.13392C4.44469 5.2902 4.65665 5.37799 4.87767 5.37799C5.09868 5.37799 5.31064 5.2902 5.46692 5.13392L8.80025 1.80058L7.62174 0.62207L4.87767 3.36615Z\" fill=\"currentcolor\"/> </svg>") calc(100% - .375rem) no-repeat;
}

.multi-selection-combobox {
  min-height: 1.75rem;
  height: initial;
}

.multi-selection-combobox .select__control, .multi-selection-combobox .select__placeholder, .multi-selection-combobox .select__value-input-container, .multi-selection-combobox .select__value-container {
  min-height: 1.25rem;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1.25rem;
}

.multi-selection-combobox .select__control--is-disabled {
  background-color: var(--input-disabled-background-color);
}

.multi-selection-combobox .select__value-container {
  align-items: initial;
}

.multi-selection-combobox .select__multi-value {
  background: var(--light-background);
  height: 1.25rem;
  border-radius: 999px;
  margin: 0 0 0 6px;
}

.multi-selection-combobox .select__multi-value:first-child {
  margin-left: 0;
}

.multi-selection-combobox .select__multi-value__label {
  align-items: center;
  padding: 0 0 0 16px;
  display: flex;
}

.multi-selection-combobox .select__multi-value__remove {
  color: var(--neutral-dark);
  cursor: pointer;
  background: none;
  padding: 0 12px 0 6px;
}

.multi-selection-combobox .select__multi-value__remove:hover {
  color: var(--selected-border);
  background: none;
}

.multi-selection-combobox .select__input-container {
  height: 1.25rem;
  margin: 0 6px 0 0;
  padding: 0;
}

.multi-selection-combobox .select__input-container input {
  height: 100%;
  outline: none;
  font-size: .4375rem;
}

.multi-selection-combobox .select__input-container input:after {
  content: "";
}

.multi-selection-combobox .select__dropdown-indicator {
  margin: 8px 4px 8px 12px;
  display: flex;
}

.multi-selection-combobox .select__menu {
  z-index: 3;
  margin-top: 0;
}

.multi-selection-combobox .select__indicators {
  align-self: flex-start;
}

.multi-selection-combobox .select__option {
  height: 1rem;
  padding: 0 .75rem;
  font-size: .4375rem;
  line-height: 1rem;
}

.multi-selection-combobox .select__option--is-focused {
  background: var(--light-background);
}

.multi-selection-combobox .select-remove-icon {
  width: 10px;
  background: red;
}

input[type="checkbox"] {
  width: .7rem;
  height: .7rem;
  margin: 0;
  padding: 0;
}

form .form-row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

form .form-row.two > * {
  max-width: calc(50% - .4em);
  flex-grow: 1;
}

a[href] {
  color: var(--link-color);
}

a.form-button, button[type="submit"] {
  height: var(--button-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  color: var(--button-color);
  background: var(--button-background-color);
  border-radius: var(--border-radius-default / 2);
  cursor: pointer;
  width: calc(100% - 2rem);
  z-index: 1;
  border: none;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  text-decoration: none;
  display: flex;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}

@media (min-width: 769px) {
  a.form-button, button[type="submit"] {
    position: initial;
    max-width: 320px;
    width: 100%;
    margin-top: 1rem;
  }
}

a.form-button:hover, button[type="submit"]:hover:enabled {
  background: var(--button-hover-background-color);
}

button:disabled {
  opacity: .4;
  pointer-events: none;
  cursor: default;
}

.tooltip {
  max-width: 400px;
  line-height: 1.2;
}

div.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker {
  zoom: .7;
  line-height: 1.1;
}

.react-datepicker input, .react-datepicker select {
  height: initial;
  margin: .3rem 0;
  font-size: .75rem;
}

@media (min-width: 769px) {
  .react-datepicker {
    zoom: .5;
  }
}

.react-datepicker .react-datepicker__navigation--next, .react-datepicker .react-datepicker__navigation--previous {
  zoom: 1.5;
}

.react-datepicker-wrapper, .react-datepicker-wrapper input {
  width: 100%;
}

.admonition {
  display: flex;
}

.admonition.is-warning .admonition-message-body {
  color: #000;
  background-color: rgba(255, 157, 66, .04);
  border-color: #ff9d42;
}

.admonition.is-danger .admonition-message-body {
  color: #000;
  background-color: #fff8f9;
  border-color: #ff506f;
}

.admonition.is-info .admonition-message-body {
  color: #000;
  background-color: rgba(49, 213, 250, .09);
  border-color: #31d5fa;
}

.admonition.is-security-info .admonition-message-body {
  color: #000;
  background-color: rgba(46, 117, 255, .05);
  border-color: #2e75ff;
}

.admonition .admonition-message-body {
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 21px;
}

.admonition .admonition-message-body-title {
  display: flex;
}

.admonition .admonition-message-body-title .admonition-message-body-title-text {
  padding-left: 13px;
  font-size: 14px;
  font-weight: 600;
}

.admonition .admonition-message-body-container {
  padding-left: 30px;
}

.data-collection-person-section:not(:first-child) {
  border-top: 1px solid #d9d9d9;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}

.data-collection-subheader {
  color: #7a7a7a;
  align-items: center;
  gap: 4px;
  font-size: .5rem;
  line-height: 1rem;
  display: flex;
}

.data-collection-subheader .icon {
  font-size: .375rem;
}

.application-form {
  min-height: calc(100vh - var(--header-height-mobile) );
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 769px) {
  .application-form {
    min-height: calc(100vh - var(--header-height-desktop) );
    flex-direction: row;
    justify-content: flex-start;
  }
}

.application-form-central-container {
  justify-content: center;
}

@media (min-width: 769px) {
  .application-form-central-container .application-form-container .individual-application-created {
    background: var(--body-background-color);
    padding: var(--base-horizontal-padding) var(--base-vertical-padding);
  }
}

.application-form-container {
  padding: var(--base-vertical-padding) var(--base-horizontal-padding);
  max-width: var(--form-max-width);
}

.application-form-container .form-type-buttons {
  flex-direction: column;
  margin: 1rem 0;
  display: flex;
}

.application-form-container .form-type {
  border: 1px solid var(--light-border);
  cursor: pointer;
  max-width: 100%;
  height: 100px;
  background: var(--form-type-background-color);
  color: var(--form-type-color);
  font-size: var(--form-type-font-size);
  white-space: break-spaces;
  border-radius: var(--border-radius-default);
  align-items: center;
  margin-bottom: .6rem;
  padding: .5rem;
  line-height: 1rem;
  display: flex;
}

.application-form-container .form-type .icon {
  margin-right: 10px;
}

.application-form-container .form-type.selected {
  border-color: var(--selected-border);
  color: var(--text-selected-color);
}

.application-form-container .form-type .type-title {
  font-weight: bold;
}

@media (min-width: 769px) {
  .application-form-container .form-type {
    height: auto;
    min-height: 50px;
    font-size: .5em;
    line-height: .6rem;
  }
}

@media (min-width: 769px) {
  .application-form-container {
    flex-grow: 1;
    order: 2;
  }
}

.application-form-container .back-button {
  cursor: pointer;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
}

.application-form-container .back-button.disabled {
  visibility: hidden;
}

.application-form-container .back-button .icon {
  margin-right: 4px;
}

.application-form-container .phone-verification {
  max-width: 700px;
}

.application-form-container input[disabled] {
  color: var(--input-disabled-color);
}

.form-current-stage-indicator {
  padding: var(--base-vertical-padding) var(--base-horizontal-padding) 3rem;
  background: var(--stage-indicator-background-color);
  box-shadow: var(--stage-indicator-box-shadow);
  font-size: var(--stage-indicator-font-size);
  white-space: pre;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.form-current-stage-indicator .stages {
  width: 100%;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.form-current-stage-indicator .stages .line {
  height: 2px;
  background: #eeeff1;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

@media (min-width: 769px) {
  .form-current-stage-indicator .stages .line {
    transform: initial;
    height: initial;
    width: 2px;
    top: .4rem;
    bottom: .4rem;
    left: .32rem;
  }
}

.form-current-stage-indicator .stages .stage {
  align-items: center;
  font-size: .5625rem;
  display: flex;
}

@media (min-width: 769px) {
  .form-current-stage-indicator .stages .stage {
    margin-bottom: 1rem;
  }

  .form-current-stage-indicator .stages .stage:last-child {
    margin-bottom: 0;
  }
}

.form-current-stage-indicator .stages .stage .stage-circle {
  width: .6875rem;
  height: .6875rem;
  z-index: 1;
  background: #eeeff1;
  border: 1px solid #d2d4db;
  border-radius: 50%;
  position: relative;
}

.form-current-stage-indicator .stages .stage .stage-circle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-current-stage-indicator .stages .stage .stage-text {
  display: none;
}

@media (min-width: 769px) {
  .form-current-stage-indicator .stages .stage .stage-text {
    opacity: .5;
    margin-left: .4rem;
    display: block;
  }
}

.form-current-stage-indicator .stages .stage.hidden {
  display: none;
}

.form-current-stage-indicator .stages .stage.clickable {
  cursor: pointer;
}

.form-current-stage-indicator .stages .stage.completed .stage-circle {
  background: var(--success-color);
}

.form-current-stage-indicator .stages .stage.completed .stage-circle:after {
  color: #fff;
  content: "✔";
  font-size: .6em;
}

.form-current-stage-indicator .stages .stage.current .stage-circle {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 3px 6px rgba(198, 198, 198, .5);
}

.form-current-stage-indicator .stages .stage.current .stage-circle:after {
  width: .25rem;
  height: .25rem;
  content: " ";
  background: var(--success-color);
  border-radius: 50%;
}

@media (min-width: 769px) {
  .form-current-stage-indicator .stages .stage.current .stage-text {
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .form-current-stage-indicator .stages {
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .form-current-stage-indicator {
    position: initial;
    padding: var(--base-vertical-padding) var(--base-horizontal-padding);
    width: 390px;
    flex-grow: 0;
    flex-shrink: 0;
    order: 1;
    justify-content: flex-start;
    box-shadow: 0 5px 5px -5px rgba(15, 23, 61, .25);
  }
}

@media (min-width: 769px) {
  .form-current-stage-indicator .bottom-text {
    display: none;
  }
}

.beneficial-owner {
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  margin-top: 1rem;
}

.beneficial-owner .beneficial-owner-title {
  border-bottom: 1px solid var(--textfield-border-color);
  padding: .8rem 1rem;
}

.beneficial-owner .beneficial-owner-form {
  padding: .8rem 1rem;
}

.business-contact {
  margin-top: 1.2rem;
}

.form-type-selection .submit-wrapper {
  margin-top: 1rem;
}

.agreement-checkbox .links {
  margin-left: .9rem;
  font-size: .5em;
  line-height: .8rem;
}

.bank-notice {
  font-size: .5em;
}

.address {
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  margin-top: 1rem;
  padding: 1rem;
}

.address h4 {
  margin-bottom: 1rem;
}

.address h4 .tooltip-icon {
  font-size: .5rem;
}

.address {
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  margin-top: 1rem;
  padding: 1rem;
}

.address h4 {
  margin-bottom: 1rem;
}

.address h4 .tooltip-icon {
  font-size: .5rem;
}

.application-status h1, .application-status p {
  margin-bottom: 1.5rem;
}

.application-status .approved .icon {
  color: var(--success-color);
}

.application-status .denied .icon {
  color: var(--invalid-color);
}

.application-status .pending p {
  white-space: pre-line;
}

.application-status .pending .loading {
  text-align: center;
  font-size: .6em;
  line-height: 1rem;
}

.application-status .pending .buttons {
  display: flex;
}

.application-status .pending .buttons .try-again-button {
  width: 100%;
  position: static;
}

@media (min-width: 769px) {
  .application-status .pending .buttons .try-again-button {
    margin-right: 10px;
  }
}

.application-documents .doc {
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-default);
  margin-bottom: 1rem;
  overflow: hidden;
}

.application-documents .doc .doc-details {
  font-size: var(--p-font-size);
  background-color: var(--stage-indicator-background-color);
  border-bottom: 1px solid var(--light-border);
  line-height: var(--p-line-height);
  padding: .66rem 1rem;
}

.application-documents .doc .doc-details .doc-type {
  color: var(--text-selected-color);
  font-weight: bold;
}

.application-documents .doc .doc-details .description {
  color: var(--text-primary-color);
  margin-top: .5rem;
}

.application-documents .doc .doc-status {
  padding: .66rem 1rem;
  font-size: .5625rem;
  line-height: 1.2rem;
}

.application-documents .doc .doc-status .upload-title {
  margin-bottom: .5rem;
  font-size: .45rem;
  line-height: 1.1;
}

.application-documents .doc .doc-status .loading {
  margin-top: .5rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.2rem;
  display: flex;
}

.application-documents .doc .doc-status .upload-button {
  border-radius: var(--border-radius-default);
  cursor: pointer;
  border: 1px solid #000;
  justify-content: center;
  align-items: center;
  padding: 0 .5rem;
  font-size: .5625rem;
  line-height: 1.2rem;
  display: inline-flex;
  position: relative;
}

.application-documents .doc .doc-status .upload-button input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.application-documents .doc .doc-status .upload-button input[type="file"], .application-documents .doc .doc-status .upload-button input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.application-documents .doc .doc-status .document-approved .icon, .application-documents .doc .doc-status .document-received .icon {
  color: var(--success-color);
}

.application-documents .doc .doc-status .document-received, .application-documents .doc .doc-status .upload-error {
  min-height: 1.6rem;
  background-color: var(--success-background-color);
  align-items: center;
  margin: 0 -1rem;
  padding: 0 1rem;
  line-height: 1.2;
  display: flex;
}

.application-documents .doc .doc-status .upload-error {
  background-color: var(--error-background-color);
  justify-content: space-between;
}

.application-documents .doc .doc-status .upload-error .icon {
  color: var(--error-color);
}

.application-documents .doc .doc-status .upload-error .upload-button {
  border: none;
  padding: 0;
}

.application-documents .doc .doc-status .document-invalid .icon {
  color: var(--error-color);
}

.application-documents .doc .doc-status .document-invalid .reason {
  font-size: .45rem;
  line-height: 1.2;
}

.application-documents .doc .upload-row {
  font-size: .5625rem;
}

.application-documents .doc .upload-row:not(:first-child) {
  margin-top: .5rem;
}

.application-documents .doc .upload-row .or {
  margin: 0 .5rem;
}

.verification-container {
  max-width: 480px;
  justify-content: space-between;
}

.verification-container > div .verification-input {
  height: 1em;
  box-sizing: content-box;
  border-radius: 4px;
  margin-left: .25em;
  margin-right: .25em;
  font-size: 15px;
}

@media (min-width: 769px) {
  .verification-container > div .verification-input {
    font-size: 24px;
  }
}

.verification-container > div:first-child .verification-input {
  margin-left: 0;
}

.verification-container > div:last-child .verification-input {
  margin-right: 0;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid rgba(0, 0, 0, 0);
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2a87d0;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, .5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

/*# sourceMappingURL=index.f548b019.css.map */
